import React from "react";
import {get, isEmpty} from "lodash";
import Link from "components/link";
import Img from "components/image";
import blogStyle from "styles/pages/blog.module.css";

function BlogPostItemRow(props) {
  function renderFeatureImage() {
    let featureImageFluid = get(props, "frontmatter.featureImage.childImageSharp.fluid");
    if (featureImageFluid) {
      return <Img fluid={featureImageFluid} alt={props.frontmatter.title}
        className="w-24 h-24 sm:w-64 sm:h-64 mb-4 sm:mb-0 ml-4 sm:ml-8 object-cover object-center"/>;
    }
  }

  return <Link key={props.id} to={`/blog/${props.fields.slug}/`}
    className={blogStyle.hoverablePost + " sm:p-8 mb-8 sm:mb-0 block flex flex-row"}>
    <div className="flex flex-col flex-1">
      <div className="text-xs uppercase font-bold">
        {props.frontmatter.category}
      </div>
      <h2 className="text-lg sm:text-2xl font-serif font-bold leading-tight">
        {props.frontmatter.title}
      </h2>
      <div className="hidden sm:block">
        <p className="mt-2 truncate-4l" style={{height: "6rem"}}>
          {isEmpty(props.frontmatter.excerpt) ? props.excerpt : props.frontmatter.excerpt}
        </p>
      </div>
      <div className="hidden sm:block flex-1"/>
      <div className="uppercase text-xs mt-2 text-gray-600">
        {get(props, "fields.author.name")} • {props.frontmatter.formattedDate}
      </div>
    </div>
    {renderFeatureImage()}
  </Link>;
}

export default React.memo(BlogPostItemRow);
