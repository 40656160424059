import React from "react";
import { Helmet as Head } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { get, take, first, compact, isEmpty } from "lodash";
import { format as dateFormat } from "date-fns";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import useResizeObserver from "use-resize-observer";
import useViewportSizes from "use-viewport-sizes";
import Link from "components/link";
import Img from "components/image";
import DefaultLayout from "components/layout/default";
import OutlineText from "components/outline-text";
import BlogSubscribe from "components/blog-subscribe";
import BlogPostItemRow from "components/blog-post-item-row";
import blogStyle from "styles/pages/blog.module.css";
import layoutStyle from "styles/layout.module.css";
import searchIcon from "images/blog-search-icon.svg";

const ITEMS_PER_PAGE = 10;
const PAGE_TITLE = "Horangi Blog";
const PAGE_DESCRIPTION =
  "Hear from our Horangi tech experts as we go deep into up-and-coming cyber threats, new solutions, and talk about the future of cybersecurity.";

function BlogPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  let latestPostNodes = data.latestPosts.edges.map(item => item.node);
  let [latestCount, setLatestCount] = React.useState(ITEMS_PER_PAGE);
  let [postCollection, setPostCollection] = React.useState(latestPostNodes);
  let viewportSizes = useViewportSizes();
  let [viewportWidth, setViewportWidth] = React.useState(null);
  let [newsletterSectionRef, newsletterSectionInView] = useInView();
  let [latestPostsSectionRef, latestPostsSectionInView] = useInView();
  let [
    mainFeaturePostPlaceholderRef,
    mainFeaturePostPlaceholderWidth
  ] = useResizeObserver({ defaultWidth: 0 });
  let searchInputRef = React.useRef();

  React.useEffect(() => {
    let latest;
    if (props.location.search) {
      try {
        latest = props.location.search.match(/latest=(\d+)/)[1];
        if (latest) {
          latest = parseInt(latest);
          if (latest > 0) {
            setLatestCount(latest);
            handleLoadMoreClick({ length: latest });
          }
        }
      } catch (e) {
        //fail silently
      }
    }
  }, [handleLoadMoreClick, props.location.search]);

  React.useEffect(() => {
    setViewportWidth(viewportSizes[0]);
  }, [viewportSizes]);

  async function handleLoadMoreClick(options = {}) {
    let data;
    try {
      let response = await fetch("/blog-feed.json");
      data = await response.json();
    } catch (error) {
      // TODO: inform user
      return;
    }
    let items = get(data, "items", []);
    if (isEmpty(items)) return false;
    let length = options.length;
    if (length == null) {
      length = postCollection.length + ITEMS_PER_PAGE;
    }
    let newCollection = take(items, length);
    newCollection = newCollection.map(item => {
      let urlParts = item.url.split("/");
      let slug = urlParts[urlParts.length - 2];
      return {
        id: item.id,
        fields: {
          slug,
          author: item._author
        },
        frontmatter: {
          title: item.title,
          excerpt: item.summary,
          category: first(item._category),
          featureImage: item.image,
          date: item.date_modified,
          formattedDate: dateFormat(item.date_modified, "MMM DD, YYYY")
        }
      };
    });
    setPostCollection(newCollection);
    setLatestCount(newCollection.length);
    history.replaceState(null, null, `/blog/?latest=${newCollection.length}`);
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
    let keyword = searchInputRef.current.value;
    if (isEmpty(keyword)) return;
    window.location.href = `https://www.google.com/search?q=site:https://blog.horangi.com%20${keyword}`;
  }

  function renderSubFeaturePost(data) {
    if (isEmpty(data)) return null;
    let featureImage;
    let featureImageFluid = get(
      data,
      "frontmatter.featureImage.childImageSharp.fluid"
    );
    if (featureImageFluid) {
      featureImage = (
        <Img
          fluid={featureImageFluid}
          alt={data.frontmatter.title}
          className="w-24 h-24 sm:w-full sm:h-auto object-cover object-center ml-4 sm:ml-0"/>
      );
    }
    return (
      <Link
        key={data.id}
        to={`/blog/${data.fields.slug}/`}
        className="sm:block md:inline-block w-full mb-4 sm:mb-8 flex flex-row-reverse">
        {featureImage}
        <div className="flex-1" style={{ breakInside: "avoid" }}>
          <div className="text-xs uppercase font-bold sm:mt-4">
            {data.frontmatter.category}
          </div>
          <h2 className="text-lg sm:text-2xl font-serif font-bold leading-tight">
            {data.frontmatter.title}
          </h2>
          <div className="hidden sm:block">
            <p
              className="text-sm mt-2 flex-none truncate-4l"
              style={{ height: "5.25rem" }}>
              {isEmpty(data.frontmatter.excerpt)
                ? data.excerpt
                : data.frontmatter.excerpt}
            </p>
          </div>
          <div className="uppercase text-xs mt-2 text-gray-600">
            {get(data, "fields.author.name")} • {data.frontmatter.formattedDate}
          </div>
        </div>
      </Link>
    );
  }

  function renderSubFeaturePosts() {
    if (isEmpty(pageContent.subFeaturePostItems)) {
      return null;
    }
    if (isEmpty(data.subFeaturePosts.edges)) {
      return null;
    }
    let posts = pageContent.subFeaturePostItems.map(item => {
      let edge = data.subFeaturePosts.edges.find(_item => {
        return _item.node.frontmatter.title === item.subFeaturePost;
      });
      if (edge) return edge.node;
    });
    posts = compact(posts);
    if (viewportWidth && viewportWidth < 640) {
      posts = take(posts, 3);
    }
    return posts.map(item => renderSubFeaturePost(item));
  }

  function renderLatestPosts() {
    return postCollection.map(item => {
      return <BlogPostItemRow key={item.id} {...item}/>;
    });
  }

  function renderLoadMoreButton() {
    if (postCollection.length === data.latestPosts.totalCount) return null;
    return (
      <button
        className="p-4 border-b block w-full text-center leading-none focus:outline-none focus:shadow-outline hover:bg-gray-100"
        style={{ transition: "all 320ms" }}
        onClick={handleLoadMoreClick}>
        Load more
      </button>
    );
  }

  function renderMainFeaturePost() {
    let post = get(data, "mainFeaturePosts.edges[0].node");
    if (isEmpty(post)) return;
    let width, bottom, containerClassName;
    let height = "calc(100vh - 10rem)";
    let bottomSectionInView =
      latestPostsSectionInView || newsletterSectionInView;
    if (bottomSectionInView || (viewportWidth && viewportWidth < 640)) {
      if (viewportWidth && viewportWidth < 640) {
        width = "100%";
        height = "auto";
      } else {
        width = "50%";
      }
      bottom = "2rem";
      containerClassName =
        "flex-1 flex flex-col mb-8 sm:mb-0 sm:pr-4 sm:absolute sm:left-0";
    } else {
      width = mainFeaturePostPlaceholderWidth;
      bottom = "auto";
      containerClassName = "fixed flex flex-col overflow-hidden";
    }
    let featureImage;
    let featureImageFluid = get(
      post,
      "frontmatter.featureImage.childImageSharp.fluid"
    );
    if (featureImageFluid) {
      featureImage = (
        <Img
          fluid={featureImageFluid}
          alt={post.title}
          className={
            blogStyle.mainFeaturePostImage +
            " flex-none sm:flex-1 object-cover object-center"
          }/>
      );
    }
    return (
      <Link
        to={`/blog/${post.fields.slug}/`}
        className={containerClassName}
        style={{ width, height, bottom, maxHeight: "62rem" }}>
        {featureImage}
        <div className="text-xs uppercase font-bold mt-4">
          {post.frontmatter.category}
        </div>
        <h2 className="text-3xl font-serif font-bold leading-tight flex-none">
          {post.frontmatter.title}
        </h2>
        <p className="mt-2 h-24 flex-none truncate-4l">
          {isEmpty(post.frontmatter.excerpt)
            ? post.excerpt
            : post.frontmatter.excerpt}
        </p>
        <div className="uppercase text-xs mt-2 text-gray-600">
          {get(post, "fields.author.name")} • {post.frontmatter.formattedDate}
        </div>
      </Link>
    );
  }

  return (
    <DefaultLayout
      location={props.location}
      headerFill="always"
      title={PAGE_TITLE}
      description={PAGE_DESCRIPTION}>
      <Head htmlAttributes={{ lang: "en", class: "default-layout" }}>
        <link
          rel="next"
          href={`/blog/?latest=${latestCount + ITEMS_PER_PAGE}`}/>
        <link
          rel="prev"
          href={`/blog/?latest=${latestCount - ITEMS_PER_PAGE}`}/>
      </Head>
      <div className={layoutStyle.headerPlaceholder}/>
      <section className="bg-white text-black pb-4 sm:pb-20">
        <div
          className={
            blogStyle.widerContainer +
            " container mx-auto px-4 md:px-8 pt-4 md:pt-8 z-10"
          }>
          <div className="relative flex flex-col sm:flex-row">
            {renderMainFeaturePost()}
            <div
              ref={mainFeaturePostPlaceholderRef}
              className="flex-1 w-1/2 pr-4 hidden sm:block"/>
            <div
              className={classNames(
                blogStyle.subFeaturePosts,
                "flex-1 w-full sm:w-1/2 sm:pl-4"
              )}>
              <div className="inline-block w-full">
                <OutlineText
                  tagName="h2"
                  className="font-sans text-3xl sm:text-4xl font-black leading-none">
                  Explore:
                </OutlineText>
                <h2 className="font-sans text-3xl sm:text-4xl font-black leading-none tracking-wide mb-6 mt-2">
                  Featured List
                </h2>
              </div>
              {renderSubFeaturePosts()}
            </div>
          </div>
        </div>
      </section>
      <section ref={newsletterSectionRef} className="bg-black text-white">
        {/* <div className="container relative mx-auto px-4 md:px-8">
        <div className="flex">
          <div className="relative hidden md:block w-1/2 pr-8">
            <div className="absolute top-0 left-0 h-full w-full z-10 pr-8">
              <Img fluid={data.newsletterVisualImage.childImageSharp.fluid}
                alt="Newsletter Visual" objectFit="cover" className="w-full h-full"/>
            </div>
          </div>
          <div className="md:w-1/2 md:pl-8 flex flex-col justify-center py-8 xl:py-20 z-20 bg-black">
            <h2 className="text-2xl lg:text-5xl font-bold leading-tight flex-none">
              Subscribe to the Horangi Newsletter.
            </h2>
            <p className="mt-4">
              Hear from our Horangi tech experts as we go deep into up-and-coming cyber threats, new solutions, and talk about the future of cybersecurity.
            </p>
            <div className={blogStyle.newsletterForm + " flex-none mt-6 overflow-hidden"}>
              <HubspotForm portalId={process.env.GATSBY_HUBSPOT_ID} formId={HUBSPOT_FORM_ID}/>
            </div>
          </div>
        </div>
      </div> */}
        <BlogSubscribe
          imgFluid={data.newsletterVisualImage.childImageSharp.fluid}/>
      </section>

      <section
        ref={latestPostsSectionRef}
        className="bg-white text-black mb-8 sm:mb-20">
        <div className="container relative mx-auto px-4 md:px-8 pt-4 sm:pt-20 z-10 max-w-5xl">
          <form className="relative" onSubmit={handleSearchSubmit}>
            <img
              src={searchIcon}
              alt="Search"
              className="absolute"
              style={{ top: "1rem", left: "0.5rem" }}/>
            <input
              type="search"
              ref={searchInputRef}
              className="block appearance-none outline-none rounded-none focus:shadow-outline w-full border-b p-4 pl-10 leading-tight mb-8 sm:mb-16"
              placeholder="Try Searching for Cyber Security Tips and Tricks…"/>
          </form>
          <OutlineText
            tagName="h2"
            color="#1515FF"
            className="font-sans text-3xl sm:text-5xl font-black leading-none">
            Latest Stories:
          </OutlineText>
          <h2
            className="font-sans text-3xl sm:text-5xl font-black leading-none mb-6 sm:mb-10 mt-2"
            style={{ color: "#1515FF" }}>
            All Categories
          </h2>
          <div className="pb-8 sm:pb-16">
            {renderLatestPosts()}
            {renderLoadMoreButton()}
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
}

const QUERY = graphql`
  {
    pageContent: file(relativePath: { eq: "pages/blog.json" }) {
      childPagesJson {
        mainFeaturePost
        subFeaturePostItems {
          subFeaturePost
        }
      }
    }
    latestPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/blog-posts//" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 10
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
            author {
              name
            }
          }
          excerpt(pruneLength: 400)
          frontmatter {
            title
            excerpt
            category
            featureImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            formattedDate: date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
    mainFeaturePosts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/blog-posts//" }
        fields: { mainFeature: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
          fields {
            slug
            author {
              name
            }
          }
          excerpt(pruneLength: 400)
          frontmatter {
            title
            excerpt
            category
            featureImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            formattedDate: date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
    subFeaturePosts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/blog-posts//" }
        fields: { subFeature: { eq: true } }
      }
      limit: 99
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
            author {
              name
            }
          }
          excerpt(pruneLength: 400)
          frontmatter {
            title
            excerpt
            category
            featureImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            formattedDate: date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
    newsletterVisualImage: file(relativePath: { eq: "newsletter-visual.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default React.memo(BlogPage);
