import React from "react";
import HubspotForm from "react-hubspot-form";
import blogStyle from "styles/pages/blog.module.css";

import Img from "components/image";

const HUBSPOT_FORM_ID = "2cd5814a-3065-42b9-b275-26fd4d59e990";

const BlogSubsribe = ({ imgFluid }) => {
  return (
    <div className="container relative mx-auto px-4 md:px-8">
      <div className="flex">
        <div className="relative hidden md:block w-1/2 pr-8">
          <div className="absolute top-0 left-0 h-full w-full z-10 pr-8">
            <Img
              fluid={imgFluid}
              alt="Newsletter Visual"
              objectFit="cover"
              className="w-full h-full"/>
          </div>
        </div>
        <div className="md:w-1/2 md:pl-8 flex flex-col justify-center py-8 xl:py-20 z-20 bg-black">
          <h2 className="text-2xl lg:text-5xl font-bold leading-tight flex-none">
            Subscribe to the Horangi Newsletter.
          </h2>
          <p className="mt-4">
            Hear from our Horangi tech experts as we go deep into up-and-coming
            cyber threats, new solutions, and talk about the future of
            cybersecurity.
          </p>
          <div
            className={
              blogStyle.newsletterForm + " flex-none mt-6 overflow-hidden"
            }>
            <HubspotForm
              portalId={process.env.GATSBY_HUBSPOT_ID}
              formId={HUBSPOT_FORM_ID}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSubsribe;
