import React from "react";

function OutlineText(props) {
  let tagName = props.tagName || "div";
  props = {...props};
  delete props.tagName;
  props.style = props.style || {};
  props.style.WebkitTextStrokeWidth = props.width || "1.5px";
  props.style.WebkitTextStrokeColor = props.color;
  props.style.WebkitTextFillColor = "transparent";
  return React.createElement(tagName, props, props.children);
}

export default React.memo(OutlineText);
